<template>
  <section class="promise white">
    <v-container>
      <v-row>
        <v-col
          v-for="(info, i) in tabs"
          :key="i"
          cols="6"
          sm="6"
          md="6"
          lg="3"
          class="d-flex flex-column align-center"
        >
          <img :src="info.img" alt="icon" width="40px" height="40px" />
          <span class="promise__text">{{ info.text }}</span>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Promise',

  data() {
    return {}
  },

  computed: {
    tabs() {
      return [
        {
          img: require('@/assets/img/svg/safe-blue.svg'),
          text: this.$t('100_authentic')
        },
        {
          img: require('@/assets/img/svg/s-car-blue.svg'),
          text: this.$t('7day_returns')
        },
        {
          img: require('@/assets/img/svg/car-blue.svg'),
          text: this.$t('shipping_discounts')
        },
        {
          img: require('@/assets/img/svg/pay-blue.svg'),
          text: this.$t('safe_payment')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.promise {
  &__text {
    font-size: 12px;
    font-weight: 700;
    opacity: 0.7;
  }
}
</style>
