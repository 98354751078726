var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbar"},[(_vm.topBannerVisible && !_vm.loading && _vm.data.top_banner.img)?_c('div',{staticClass:"position-relative"},[_c('dynamic-link',{attrs:{"to":_vm.data.top_banner.link,"append-class":"text-reset d-block lh-0"}},[_c('img',{staticClass:"img-fit h-50px w-100",attrs:{"src":_vm.addResourceImage(_vm.data.top_banner.img)}})]),_c('v-btn',{staticClass:"absolute-top-right rounded border-2 btn-xxs mt-2 me-2",attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","color":"white"},nativeOn:{"click":function($event){return _vm.closeTopBanner.apply(null, arguments)}}},[_c('i',{staticClass:"las la-times ts-10"})])],1):_vm._e(),_c('v-container',{staticClass:"fs-13 py-0 px-0 px-md-3"},[_c('v-row',{staticClass:"my-0 d-none d-md-flex",attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center"},[(
              _vm.data.show_language_switcher == 'on' && _vm.allLanguages.length > 1
            )?_c('v-menu',{attrs:{"offset-y":"","close-on-click":_vm.menuCloseOnClick,"elevation":2,"z-index":"99999"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"d-flex align-center"},'span',attrs,false),on),[_c('span',{},[_vm._v(_vm._s(_vm.userLanguageObj.name))]),_c('i',{staticClass:"las la-angle-down ms-1 fs-12"})])]}}],null,false,1062464912)},[_c('v-list',{staticClass:"fs-13"},_vm._l((_vm.allLanguages),function(language,i){return _c('v-list-item',{key:i,staticClass:"c-pointer d-flex align-center",on:{"click":function($event){return _vm.switchLanguage(language.code)}}},[_c('img',{staticClass:"me-1 h-10px",attrs:{"src":require(`@/assets/img/flags/${language.flag}.png`)}}),_c('v-list-item-title',{staticClass:"fs-13"},[_vm._v(" "+_vm._s(language.name)+" ")])],1)}),1)],1):_vm._e(),(
              _vm.data.show_language_switcher == 'on' && _vm.allLanguages.length > 1
            )?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","close-on-click":_vm.menuCloseOnClick,"elevation":2,"z-index":"99999"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"d-flex align-center"},'span',attrs,false),on),[_c('span',{},[_vm._v(_vm._s(_vm.unit?.name))]),_c('i',{staticClass:"las la-angle-down ms-1 fs-12"})])]}}])},[_c('v-list',{staticClass:"fs-13"},_vm._l((_vm.units),function(unit,i){return _c('v-list-item',{key:i,staticClass:"c-pointer d-flex align-center",on:{"click":function($event){return _vm.switchUnit(unit)}}},[_c('v-list-item-title',{staticClass:"fs-13 opacity-60"},[_vm._v(" "+_vm._s(unit.name)+" - "+_vm._s(unit.value)+" ")])],1)}),1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),(
              _vm.data.mobile_app_links &&
              _vm.data.mobile_app_links.show_play_store == 'on'
            )?_c('a',{staticClass:"me-4 text-reset",attrs:{"href":_vm.data.mobile_app_links?.play_store,"target":"_blank"}},[_c('i',{staticClass:"lab la-android"}),_c('span',{staticClass:"opacity-60"},[_vm._v(_vm._s(_vm.$t('play_store')))])]):_vm._e(),(
              _vm.data.mobile_app_links &&
              _vm.data.mobile_app_links.show_app_store == 'on'
            )?_c('a',{staticClass:"text-reset",attrs:{"href":_vm.data.mobile_app_links?.app_store,"target":"_blank"}},[_c('i',{staticClass:"lab la-apple"}),_c('span',{staticClass:"opacity-60"},[_vm._v(_vm._s(_vm.$t('app_store')))])]):_vm._e(),(_vm.is_addon_activated('multi_vendor'))?[(
                _vm.data.mobile_app_links &&
                (_vm.data.mobile_app_links.show_play_store == 'on' ||
                  _vm.data.mobile_app_links.show_app_store == 'on')
              )?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),_c('router-link',{staticClass:"text-reset",attrs:{"to":{ name: 'ShopRegistration' }}},[_vm._v(" "+_vm._s(_vm.$t('be_a_seller'))+" ")])]:_vm._e()],2)]),_c('v-col',{staticClass:"py-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('a',{staticClass:"text-reset",attrs:{"href":_vm.contactUrl,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('help_line_link')))])]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('router-link',{staticClass:"text-reset",attrs:{"to":{ name: 'Wishlist' }}},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t('wishlist'))+" ("+_vm._s(_vm.getTotalWishlisted)+") ")])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }